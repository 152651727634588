[
  {
    "key": "name",
    "label": "Full name",
    "mapToPath": "name"
  },
  {
    "key": "firstname",
    "label": "First name",
    "mapToPath": "details.firstName"
  },
  {
    "key": "middle_name",
    "label": "Middle name",
    "mapToPath": "details.middleName"
  },
  {
    "key": "surname",
    "label": "Surname",
    "mapToPath": "details.surname"
  },
  {
    "key": "address",
    "label": "Address",
    "mapToPath": "details.address"
  },
  {
    "key": "address_2",
    "label": "Address 2",
    "mapToPath": "details.address2"
  },
  {
    "key": "address_3",
    "label": "Address 3",
    "mapToPath": "details.address3"
  },
  {
    "key": "address_4",
    "label": "Address 4",
    "mapToPath": "details.address4"
  },
  {
    "key": "town",
    "label": "Town",
    "mapToPath": "details.town"
  },
  {
    "key": "county",
    "label": "County",
    "mapToPath": "details.county"
  },
  {
    "key": "postcode",
    "label": "Postcode",
    "mapToPath": "details.postcode"
  },
  {
    "key": "salutation",
    "label": "Salutation",
    "mapToPath": "details.salutation"
  },
  {
    "key": "known_as",
    "label": "Known as",
    "mapToPath": "details.knownAs"
  },
  {
    "key": "dob",
    "label": "Date of birth",
    "mapToPath": "details.dob"
  },
  {
    "key": "ni_number",
    "label": "NI number",
    "mapToPath": "employment.niNumber"
  },
  {
    "key": "marital_status",
    "label": "Marital status",
    "mapToPath": "details.maritalStatus"
  },
  {
    "key": "relationships",
    "label": "Relationships",
    "mapToPath": "details.relationships"
  },
  {
    "key": "gender",
    "label": "Gender",
    "mapToPath": "details.gender"
  },
  {
    "key": "email",
    "label": "Email address",
    "mapToPath": "details.email"
  },
  {
    "key": "additional_email",
    "label": "Additional email address",
    "mapToPath": "details.additionalEmail"
  },
  {
    "key": "website",
    "label": "Website",
    "mapToPath": "details.website"
  },
  {
    "key": "tel",
    "label": "Telephone number",
    "mapToPath": "details.tel"
  },
  {
    "key": "mobile",
    "label": "Telephone number (mobile)",
    "mapToPath": "details.telMobilePersonal"
  },
  {
    "key": "bank",
    "label": "Bank - bank name",
    "mapToPath": "bank.bank"
  },
  {
    "key": "account_name",
    "label": "Bank - account name",
    "mapToPath": "bank.name"
  },
  {
    "key": "account_no",
    "label": "Bank - account number",
    "mapToPath": "bank.acNo"
  },
  {
    "key": "sort_code",
    "label": "Bank - sort code",
    "mapToPath": "bank.sortCode"
  },
  {
    "key": "bank_ref",
    "label": "Bank - reference",
    "mapToPath": "bank.ref"
  },
  {
    "key": "employment_status",
    "label": "Employment status",
    "mapToPath": "employment.status"
  },
  {
    "key": "employment_type",
    "label": "Employment type",
    "mapToPath": "employment.type"
  },
  {
    "key": "department",
    "label": "Department",
    "mapToPath": "employment.department"
  },
  {
    "key": "job_title",
    "label": "Job title",
    "mapToPath": "employment.jobTitle"
  },
  {
    "key": "salary_hourly",
    "label": "Salary (monthly)",
    "mapToPath": "employment.salaryHourly"
  },
  {
    "key": "salary",
    "label": "Salary",
    "mapToPath": "employment.salary"
  },
  {
    "key": "rate",
    "label": "Rate",
    "mapToPath": "employment.rate"
  },
  {
    "key": "start_date",
    "label": "Employment start date",
    "mapToPath": "employment.began"
  },
  {
    "key": "end_date",
    "label": "Employment end date",
    "mapToPath": "employment.end"
  },
  {
    "key": "end_reason",
    "label": "Employment end - reason",
    "mapToPath": "employment.endReason"
  },
  {
    "key": "end_details",
    "label": "Employment end - details",
    "mapToPath": "employment.endDetails"
  },
  {
    "key": "end_notes",
    "label": "Employment end - notes",
    "mapToPath": "employment.endNotes"
  },
  {
    "key": "place_of_work",
    "label": "Place of work",
    "mapToPath": "employment.placeOfWork"
  },
  {
    "key": "work_pattern_details",
    "label": "Work pattern details",
    "mapToPath": "employment.workPatternDetails"
  },
  {
    "key": "hours_per_week",
    "label": "Hours per week",
    "mapToPath": "employment.hoursPerWeek"
  },
  {
    "key": "emergency_name",
    "label": "Emergency contact name",
    "mapToPath": "emergencyContacts.emergencyName"
  },
  {
    "key": "emergency_relationship",
    "label": "Emergenct contact relationship",
    "mapToPath": "emergencyContacts.emergencyRelationship"
  },
  {
    "key": "emergency_contact_number",
    "label": "Emergency contact number",
    "mapToPath": "emergencyContacts.emergencyTel"
  },
  {
    "key": "emergency_contact_number_alt",
    "label": "Emergency contact number (alternative)",
    "mapToPath": "emergencyContacts.emergencyTelAlt"
  },
  {
    "key": "emergency_email",
    "label": "Emergency contact email",
    "mapToPath": "emergencyContacts.emergencyEmail"
  },
  {
    "key": "emergency_address",
    "label": "Emergency contact address",
    "mapToPath": "emergencyContacts.emergencyAddress"
  },
  {
    "key": "emergency_address_2",
    "label": "Emergency contact address line 2",
    "mapToPath": "emergencyContacts.emergencyAddress2"
  },
  {
    "key": "emergency_postcode",
    "label": "Emergency contact address postcode",
    "mapToPath": "emergencyContacts.emergencyPostcode"
  },
  {
    "key": "emergency_name-2",
    "label": "Emergency contact 2 name",
    "mapToPath": "emergencyContacts.emergency2Name"
  },
  {
    "key": "emergency_relationship-2",
    "label": "Emergenct contact 2 relationship",
    "mapToPath": "emergencyContacts.emergency2Relationship"
  },
  {
    "key": "emergency_contact_number-2",
    "label": "Emergency contact 2 number",
    "mapToPath": "emergencyContacts.emergency2Tel"
  },
  {
    "key": "emergency_contact_number_alt-2",
    "label": "Emergency contact 2 number (alternative)",
    "mapToPath": "emergencyContacts.emergency2TelAlt"
  },
  {
    "key": "emergency_email-2",
    "label": "Emergency contact 2 email",
    "mapToPath": "emergencyContacts.emergency2Email"
  },
  {
    "key": "emergency_address-2",
    "label": "Emergency contact 2 address",
    "mapToPath": "emergencyContacts.emergency2Address"
  },
  {
    "key": "emergency_address_2-2",
    "label": "Emergency contact 2 address line 2",
    "mapToPath": "emergencyContacts.emergency2Address2"
  },
  {
    "key": "emergency_postcode-2",
    "label": "Emergency contact 2 address postcode",
    "mapToPath": "emergencyContacts.emergency2Postcode"
  }
]
