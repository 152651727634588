import { create } from "zustand";
import { ClientJS } from "clientjs";
import accessLevels from "../../components/json/accessLevels.json";
import utils from "../../utilities";

const client = new ClientJS();
const fingerprint = client.getFingerprint(); // for 2fa

const getDarkMode = () => {
  if (
    localStorage.getItem("toggleDarkMode") === "true" ||
    localStorage.getItem("toggleDarkMode") === "false"
  ) {
    return !!(localStorage.getItem("toggleDarkMode") === "true");
  }
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    return true;
  }
  return false;
};

const useStore = create((set) => ({
  getPathParts: utils.getPathParts,
  getIsAdmin: utils.getIsAdmin,
  getClientRef: utils.getClientRef,
  loggedIn: !!(localStorage.getItem("loggedIn") === "true"),
  setLoggedIn: (loggedIn) => {
    localStorage.setItem("loggedIn", loggedIn);
    set({ loggedIn });
  },
  clientRef: utils.getClientRef(),
  setClientRef: (clientRef = null) => {
    const ref = clientRef || utils.getClientRef();
    localStorage.setItem("clientRef", ref);
    set({ clientRef: ref });
  },
  clientDetails: localStorage.getItem("clientDetails"),
  setClientDetails: (clientDetails) => {
    localStorage.setItem("clientDetails", clientDetails);
    set({ clientDetails });
  },
  authToken: localStorage.getItem("authToken"),
  setAuthToken: (authToken) => {
    localStorage.setItem("authToken", authToken);
    set({ authToken });
  },
  pageTitle: "YourHR.space",
  setPageTitle: (pageTitle) => {
    // get current stored page title
    const currentTitle = useStore.getState().pageTitle;
    // if the current title is not the same as the new title
    if (currentTitle !== pageTitle) {
      // set the new title
      set({ pageTitle });
    }
  },
  test: false,
  setTest: (test) => set({ test }),
  baseURL: process.env.BASE_PATH,
  subdomain: utils.getSubdomain(),
  fingerprint: fingerprint,
  accessLevels: accessLevels,
  hasUnsavedChanges: false,
  setHasUnsavedChanges: (hasUnsavedChanges) => {
    set({ hasUnsavedChanges });
  },
  hasJustSaved: false,
  setHasJustSaved: (hasJustSaved) => {
    set({ hasJustSaved });
  },
  focussedComponent: null,
  setFocusedComponent: (componentRef) =>
    set(() => ({ focusedComponent: componentRef })),
  clearFocusedComponent: () => set(() => ({ focusedComponent: null })),
  currentUser: {},
  setCurrentUser: (currentUser) => set({ currentUser }),
  isCurrentUserLoading: false,
  setIsCurrentUserLoading: (isCurrentUserLoading) =>
    set({ isCurrentUserLoading }),
  settings: {},
  setSettings: (settings) => set({ settings }),
  isSettingsLoading: false,
  setIsSettingsLoading: (isSettingsLoading) => set({ isSettingsLoading }),

  showModal: false,
  setShowModal: (showModal) => set({ showModal }),
  modalContent: null,
  setModalContent: (modalContent) => set({ modalContent }),

  mobileMenuToggle: false,
  setMobileMenuToggle: (mobileMenuToggle) => set({ mobileMenuToggle }),
  activeMenuCategoryId: null,
  setActiveMenuCategoryId: (activeMenuCategoryId) =>
    set({ activeMenuCategoryId }),
  toggleMenuTop: !!(localStorage.getItem("toggleMenuTop") === "true"),
  setToggleMenuTop: (toggleMenuTop) => {
    localStorage.setItem("toggleMenuTop", toggleMenuTop);
    set({ toggleMenuTop });
  },
  toggleDarkMode: getDarkMode(),
  setToggleDarkMode: (toggleDarkMode) => {
    localStorage.setItem("toggleDarkMode", toggleDarkMode);

    if (toggleDarkMode) {
      document.querySelector(":root").classList.add("theme-dark");
    } else {
      document.querySelector(":root").classList.remove("theme-dark");
    }
    set({ toggleDarkMode });
  },
  toggleHighContrast: !!(localStorage.getItem("toggleHighContrast") === "true"),
  setToggleHighContrast: (toggleHighContrast) => {
    localStorage.setItem("toggleHighContrast", toggleHighContrast);
    if (toggleHighContrast) {
      document.querySelector(":root").classList.add("theme-high-contrast");
      document.querySelector(":root").classList.add("theme-large-text");
    } else {
      document.querySelector(":root").classList.remove("theme-high-contrast");
      document.querySelector(":root").classList.remove("theme-large-text");
    }
    set({ toggleHighContrast });
  },
  toggleSideMenuMobile: false,
  setToggleSideMenuMobile: (toggleSideMenuMobile) =>
    set({ toggleSideMenuMobile }),
  toggleEmployeeDbMenu: true,
  setToggleEmployeeDbMenu: (toggleEmployeeDbMenu) =>
    set({ toggleEmployeeDbMenu }),
  setToggleSettingsMenu: (toggleSettingsMenu) => set({ toggleSettingsMenu }),
}));

export default useStore;
