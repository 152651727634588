import { useEffect, useState, useRef } from "react";
import { confirmDialog } from "primereact/confirmdialog";
import Form from "./common/form";
import Skeleton from "./common/skeleton";
import BackButton from "./common/backButton";
import SubmitButton from "./common/submitButton";
import Info from "./common/info";
import Notice from "./common/notice";
import KeyValueTable from "./common/keyValueTable";
import Inbox from "./pages/components/inbox";
import ImageSelect from "./common/imageSelect";
import Absences from "./pages/components/absences";
import Submission from "./pages/components/formSubmission";
import InboxConversation from "./pages/components/inboxConversation";
import Spinner from "./common/spinner";
import CloseButton from "./common/closeButton";
import Notifications from "./pages/components/notifications";
import useStore from "./hooks/useStore";
import "../../scss/components/drawer.scss";

// Intercept close navigation with prompt if unsaved changes to form
const doCheck = (
  callback,
  hasUnsavedChanges,
  checkForUnsavedChanges = true
) => {
  // If there are no unsaved changes, navigate
  if (1 == 1 || !hasUnsavedChanges || !checkForUnsavedChanges) {
    return callback();
  }
  confirmDialog({
    message: "You have unsaved changes. Are you sure you want to close this?",
    accept: () => {
      return callback();
    },
    className: "confirm-dialog",
    acceptClassName: "button",
    rejectClassName: "button button__invert",
  });
};

const Drawer = ({
  component: componentType,
  componentProps,
  actionButtons,
  actionButtonsHeading,
  heading,
  content,
  notice,
  hideForm,
  close = false,
  loading = false,
  busy: initBusy,
  disabled = false,
  saveLabel = "Save",
  saveLabelBusy = "Saving...",
  checkForUnsavedChanges = true,
  innerRef = null,
  onOpen,
  onClose,
  onSave,
}) => {
  const hasUnsavedChanges = useStore((state) => state.hasUnsavedChanges);
  const setHasUnsavedChanges = useStore((state) => state.setHasUnsavedChanges);
  const [drawerOverlayClass, setDrawerOverlayClass] =
    useState("drawer-overlay");
  const [drawerClass, setDrawerClass] = useState("drawer");
  const [busy, setBusy] = useState(initBusy);
  const [showBackLink, setShowBackLink] = useState(false);
  const [backTrigger, setBackTrigger] = useState(false);

  useEffect(() => {
    setBusy(initBusy);
  }, [initBusy]);
  useEffect(() => {
    // custom scrollbar on content area
    require("simplebar");
    setHasUnsavedChanges(false);
  }, []);
  const formSubmitCallbackRef = useRef();
  let component = null;
  switch (componentType) {
    case "form":
      // store callback for React hook form validation / submission
      // so we can submit form from parent
      const submitCallback = (callback) => {
        formSubmitCallbackRef.current = callback;
      };
      component = (
        <Form
          registerCallback={submitCallback}
          onSubmit={onSave}
          {...componentProps}
          busy={busy}
        />
      );
      break;
    case "info":
      component = <Info data={componentProps?.data} />;
      break;
    case "dataTable":
      component = <KeyValueTable data={componentProps?.data} />;
      break;
    case "imageSelect":
      component = <ImageSelect {...componentProps} />;
      break;
    case "formSubmission":
      component = <Submission {...componentProps} />;
      break;
    case "absences":
      component = (
        <Absences {...componentProps} innerRef={innerRef} heading={heading} />
      );
      break;
    case "inboxConversation":
      component = <InboxConversation {...componentProps} />;
      break;
    case "inbox":
      component = (
        <Inbox
          {...componentProps}
          backTrigger={backTrigger}
          onBackLink={setShowBackLink}
          onBusy={setBusy}
        />
      );
      break;
    case "systemMessages":
      component = (
        <Inbox {...componentProps} isSystemMessages={true} onBusy={setBusy} />
      );
      break;
    case "notifications":
      component = <Notifications {...componentProps} onBusy={setBusy} />;
      break;
  }

  const doClose = () => {
    doCheck(
      () => {
        // drawer component has onClose prop, trigger it now
        if (componentProps?.onClose) {
          componentProps.onClose();
        }
        setHasUnsavedChanges(false);
        setDrawerOverlayClass("drawer-overlay drawer-overlay__closed");
        setDrawerClass("drawer drawer__closed");
        if (onClose) {
          setTimeout(onClose, 600);
        }
      },
      hasUnsavedChanges,
      checkForUnsavedChanges
    );
  };

  const doBack = () => {
    setShowBackLink(false);
    setBackTrigger(Math.random());
  };

  useEffect(() => {
    if (close) return doClose();
    if (onOpen) {
      setTimeout(onOpen, 500);
    }
  }, [close]);

  const disableSubmit = !!(hideForm || componentType != "form" || disabled);

  return (
    <>
      <div className={drawerOverlayClass}></div>
      <div className="drawer-container">
        <div className={drawerClass}>
          <div className="drawer--heading">
            <h2>{heading}</h2>
          </div>
          <div className="drawer--close">
            {showBackLink && (
              <BackButton label="Back to inbox" onClick={doBack} />
            )}
            <CloseButton onClick={doClose} />
          </div>
          <div
            className={
              busy || loading
                ? "drawer--content drawer--content__submitting"
                : "drawer--content"
            }
          >
            {loading && <Spinner />}
            {!loading && (
              <>
                {actionButtons && (
                  <div
                    className="drawer-action-buttons"
                    data-is-disabled={busy || disabled}
                  >
                    {actionButtonsHeading && (
                      <div className="drawer-action-buttons-heading">
                        {actionButtonsHeading}
                      </div>
                    )}
                    {actionButtons.map((actionButton, key) => (
                      <button
                        key={key}
                        className="button button__invert"
                        onClick={() =>
                          actionButton?.action(formSubmitCallbackRef.current)
                        }
                      >
                        {actionButton?.label}
                      </button>
                    ))}
                  </div>
                )}
                {notice && <Notice message={notice} />}
                {component}
              </>
            )}
          </div>
          <div className="drawer--footer-buttons">
            {!disableSubmit && (
              <>
                <SubmitButton
                  label={busy ? saveLabelBusy : saveLabel}
                  disabled={busy || loading}
                  onSubmit={() => {
                    formSubmitCallbackRef.current();
                  }}
                />
                <button
                  className={
                    busy
                      ? "button button__invert button__disabled"
                      : "button button__invert"
                  }
                  type="button"
                  disabled={busy || loading}
                  onClick={doClose}
                >
                  Close
                </button>
              </>
            )}

            {disableSubmit && (
              <button
                className={
                  busy
                    ? "button button__invert button__disabled"
                    : "button button__invert"
                }
                type="button"
                disabled={busy}
                onClick={doClose}
              >
                Close
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Drawer;
