const utils = {};
import { DateTime } from "luxon";
import { parse } from "path-browserify";

utils.getSubdomain = () => {
  const parts = window.location.host.split(".");
  if (parts.length >= 2) {
    return parts[0];
  }
  return null;
};

utils.getPathParts = () => {
  const pathParts = window.location.pathname.replace(process.env.BASE_PATH, "");
  return (pathParts && pathParts?.split("/")) || [];
};

utils.getIsAdmin = () => {
  if (localStorage.getItem("admin") === undefined) {
    return !!utils.getSubdomain() === "admin";
  }
  return !!(localStorage.getItem("admin") === "true");
};

utils.getClientRef = () => {
  if (utils.getIsAdmin()) {
    const pathParts = utils.getPathParts();
    if (pathParts.length > 0) {
      return pathParts[0] === "admin" ? "master" : pathParts[0] || null;
    }
    return "admin";
  }
  return localStorage.getItem("clientRef") || utils.getSubdomain() || null;
};

utils.getContrastingColor = (color) => {
  // Expand shorthand hex notation
  color = expandHex(color);
  // Convert input color to RGB values
  var colorRgb = hexToRgb(color);
  // Get the luminance of the input color
  var colorLuminance =
    (0.299 * colorRgb.r + 0.587 * colorRgb.g + 0.114 * colorRgb.b) / 255;
  // Return "black" if the luminance is greater than or equal to 0.5, else return "white"
  return colorLuminance >= 0.5 ? "black" : "white";
};

utils.extractYoutubeId = (url) => {
  if (!url?.match) return;
  const regExp =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url?.match(regExp);
  if (match && match[2]) {
    return match[2];
  }
  return null;
};

// Return ordinal suffix for a number (3rd, 5th etc)
utils.getOrdinalSuffix = (day) => {
  if (!day || typeof day !== "number") return;
  if (day >= 11 && day <= 13) {
    return "th";
  } else {
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }
};

// Convert weeks to days
utils.getDaysFromWeeks = (weeks = 0) => {
  weeks = parseFloat(weeks);
  const daysPerWeek = 5;
  return (weeks * daysPerWeek)?.toFixed(1);
};

// Return formatted date string
utils.getFormattedDate = (date, format = "d MMMM yyyy", friendly = false) => {
  if (!date) return null;
  const dateObj = DateTime.fromISO(date);
  if (!dateObj.isValid) return null;

  let formattedDate;

  if (format === "full") {
    const day = dateObj.day;
    const ordinalSuffix = utils.getOrdinalSuffix(day);
    formattedDate = dateObj.toFormat(`EEEE, d'${ordinalSuffix}' MMMM`);
  } else {
    formattedDate = dateObj.toFormat(format);
  }

  if (!friendly) return formattedDate;

  const today = DateTime.local();
  const yesterday = today.minus({ days: 1 });
  const todayFormatted = today.toFormat(format);
  const yesterdayFormatted = yesterday.toFormat(format);

  return formattedDate === todayFormatted
    ? "Today"
    : formattedDate === yesterdayFormatted
    ? "Yesterday"
    : formattedDate;
};

// Return formatted date string with time
utils.getFormattedDateTime = (date) => {
  if (!date) return "-";
  const dateObj = DateTime.fromISO(date);
  if (!dateObj.isValid) return "-";

  const formattedDate = dateObj.toFormat("d MMMM yyyy, h:mm a");
  return formattedDate;
};

// Ensure 'regularDays' value is correct format for select options
const defaultRegularDays = [false, true, true, true, true, true, false, false];
utils.formatRegularWorkingDays = (regularDays = defaultRegularDays) => {
  if (Array.isArray(regularDays) && regularDays.length === 0) {
    regularDays = defaultRegularDays;
  }
  const days = [];
  const keys = Object.keys(regularDays);
  keys?.forEach((key) => {
    if (regularDays[key] && key > 0) {
      days.push({
        value: parseInt(key),
        label: DateTime.fromObject({ weekday: parseInt(key) }).toFormat("cccc"),
      });
    }
  });
  return days;
};

// Remove any HTML tags
utils.stripTags = (str, retainLinebreaks = false) => {
  if (!str || typeof str !== "string") return;
  if (retainLinebreaks) {
    str = str.replace(/<\/(p|div|h1|h2|h3|h4|h5|h6)>|<br\s*\/?>/g, "[BREAK]");
  }
  str = str.replace(/(<([^>]+)>)/gi, "");
  if (retainLinebreaks) {
    str = str.replace(/\[BREAK\]/g, "<br />");
  }
  return str;
};

// Parse text and make substitutions
utils.parseShortcuts = (str, substitutions = {}) => {
  if (!str || typeof str !== "string") return;
  if (!substitutions || typeof substitutions !== "object") return;
  // Replace any substitutions
  for (const key in substitutions) {
    if (substitutions.hasOwnProperty(key)) {
      let value =
        substitutions[key] ||
        "<span class='text-missing'>(information missing)</span>";
      if (key === "e") {
        value = `<a href="mailto:${value}">${value}</a>`;
      }
      str = str.replace(new RegExp(`\\[${key}\\]`, "gi"), value);
    }
  }
  return str;
};

// Access a nested object property by path
utils.getNested = (obj, path, defaultValue = null) => {
  if (!obj || typeof obj !== "object") return defaultValue;
  if (!path || typeof path !== "string") return defaultValue;
  const pathArray = path.split(".");
  let value = obj;
  for (let i = 0; i < pathArray.length; i++) {
    const key = pathArray[i];
    if (value.hasOwnProperty(key)) {
      value = value[key];
    } else {
      return defaultValue;
    }
  }
  return value;
};

// Deep find a nested object by key and value
utils.findNested = (obj, key, value) => {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      if (prop === key && obj[prop] === value) {
        return obj;
      } else if (typeof obj[prop] === "object") {
        const nestedResult = utils.findNested(obj[prop], key, value);
        if (nestedResult) {
          return nestedResult;
        }
      }
    }
  }
  return null;
};

// Convert an array into another format, e.g. for react-select
utils.generateSelectOptions = (
  data = [],
  valueKey,
  labelKey,
  outputValue = "value",
  outputLabel = "label"
) => {
  const options = data
    ?.filter((item) => {
      if (typeof item === "object") {
        return !!item?.[labelKey];
      }
      if (typeof item === "string") {
        return !!item;
      }
      return true;
    })
    ?.map((item) => {
      const isObject = typeof item === "object";
      const option = {
        [outputValue]: isObject ? item?.[valueKey] : item,
        [outputLabel]: isObject ? item?.[labelKey] : item,
      };
      return option;
    });
  return options;
};

// Parse output from react-select into valid data for submission
utils.parseSelectOptions = (data) => {
  if (
    !data ||
    (!Array.isArray(data) && typeof data != "object" && typeof data != "string")
  )
    return null;
  if (typeof data === "string") return data;
  if (typeof data === "object" && !Array.isArray(data)) {
    return data.value || null;
  }
  const parsedData = data.map((item) => {
    return item.value;
  });
  return parsedData;
};

// Validate a password
utils.isPasswordInvalid = (password, confirm) => {
  if (!password || !confirm) return "Please enter a password and confirmation";
  if (password !== confirm) return "Passwords do not match";
  if (password.length < 8) return "Password must be at least 8 characters";
  if (password.length > 26) return "Password must be fewer than 26 characters";
  if (!password.match(/^$|[a-z]+/))
    return "Password must contain a lowercase letter";
  if (!password.match(/^$|[A-Z]+/))
    return "Password must contain an uppercase letter";
  if (!password.match(/^$|[0-9]+/)) return "Password must contain a number";
  if (!password.match(/^$|[^a-zA-Z0-9]+/))
    return "Password must contain a symbol";
  return null;
};

utils.getTitleFromLongContent = (str, maxLen = 50) => {
  if (!str || typeof str !== "string") return;
  // Split the string by any line breaks, '</p>', '<br />' or '<br>',
  // as well as sentence-ending punctuation (. ! ?)
  const splitPoints = ["\n", "</p>", "<br />", "<br>", ".", "!", "?"];
  let splitStr = str;
  let foundSplitPoint = false;
  for (let i = 0; i < splitPoints.length; i++) {
    const splitIndex = splitStr.indexOf(splitPoints[i]);
    if (splitIndex !== -1 && splitIndex < splitStr.length - 1) {
      if (splitPoints[i] === ".") {
        // Check if the period is followed by a space
        if (
          splitIndex + 1 >= splitStr.length ||
          splitStr[splitIndex + 1] === " "
        ) {
          foundSplitPoint = true;
        }
      } else {
        foundSplitPoint = true;
      }
      if (foundSplitPoint) {
        splitStr = splitStr.substring(0, splitIndex);
        // Only split on the first sentence-ending punctuation found
        if (
          splitPoints[i] === "." ||
          splitPoints[i] === "!" ||
          splitPoints[i] === "?"
        ) {
          break;
        }
      }
    }
  }

  // Remove any HTML tags, and truncate
  const truncated = utils.truncateString(splitStr, maxLen);

  // If we have split the string, but the truncated string does not already
  // end with an ellipsis, add one to the end
  if (foundSplitPoint && truncated.at(-1) !== "…") {
    return truncated + "…";
  }
  return truncated;
};

// Truncate a string to a certain length, retaining whole words
utils.truncateString = (
  str,
  maxLen = 50,
  brSplit = 0,
  retainLinebreaks = false
) => {
  // Remove any HTML tags
  const plainTextStr = utils.stripTags(str, retainLinebreaks);

  // Limit the output string to 50 characters
  let truncatedStr = plainTextStr.substring(0, maxLen);
  if (truncatedStr.length === maxLen) {
    // Use the last whole word as a cut-off point
    const lastSpaceIndex = truncatedStr.lastIndexOf(" ");
    if (lastSpaceIndex !== -1) {
      truncatedStr = truncatedStr.substring(0, lastSpaceIndex);
    }
  }
  const textHasChanged = plainTextStr !== truncatedStr;
  truncatedStr = utils.breakSplit(truncatedStr, brSplit);
  return !textHasChanged ? truncatedStr : truncatedStr + "…";
};

// add a zero-width break at the specified interval
utils.breakSplit = (text, limit = 0) => {
  if (limit === 0) return text;
  // split string into words
  const words = text.split(" ");
  // hold lines of text in an array
  const lines = [];
  // loop through each word
  words.forEach((word, index) => {
    const lastLine = lines[lines.length - 1];
    // get total number of characters for all elements in last line
    const lastLineLength = lastLine
      ? lastLine.reduce((acc, val) => acc + val.length, 0)
      : 0;
    // would adding this word to the last line exceed the limit?
    if (lastLine && lastLineLength + word.length <= limit) {
      lastLine.push(word);
    } else {
      // is the word longer than the limit?
      if (word.length > limit) {
        // add breakpoints at the specified interval
        const regex = new RegExp(`(.{${limit}})`, "g");
        word = word.replace(regex, "$1\u00AD"); //200B
      }
      // create a new line
      lines.push([word]);
    }
  });
  // join the lines with spaces
  return lines.map((line) => line.join(" ")).join(" ");
};

// convert a camelCase string to a sentence
utils.camelCaseToSentence = (str) => {
  if (!str || typeof str !== "string") return;

  // Use a regular expression to split the string at uppercase letters, spaces, underscores, and hyphens
  const words = str.split(/(?=[A-Z])|_|-/);

  // Filter out empty strings and convert all words to lowercase, except the first one
  const sentenceCaseWords = words
    .filter((word) => word.trim() !== "")
    .map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        return word.toLowerCase();
      }
    });

  // Join the words with spaces to form the sentence case string
  return sentenceCaseWords.join(" ");
};

// convert an array to CSV
utils.arrayToCsv = (data, delimiter = ",") => {
  // get all the properties to use as headings
  let headings = [];
  data.forEach((row) => {
    if (row && typeof row === "object") {
      Object.keys(row).forEach((key) => {
        if (!headings.includes(key)) {
          headings.push(key);
        }
      });
    }
  });
  const rows = [];
  // loop through each item in the array and add the values to a new array
  data.forEach((row) => {
    if (row && typeof row === "object") {
      const newRow = [];
      headings.forEach((heading) => {
        newRow.push(row[heading] || "");
      });
      rows.push(newRow);
    }
  });
  // add the headings to the start of the array
  rows.unshift(headings);
  // convert the array to a CSV string
  return utils.arrayToCsvString(rows, delimiter);
};
utils.arrayToCsvString = (data, delimiter = ",") => {
  if (!data || !Array.isArray(data)) return;
  return data
    .map(
      (row) =>
        row
          ?.map(String) // convert every value to String
          ?.map((v) => v.replaceAll('"', '""')) // escape double quotes
          ?.map((v) => `"${v}"`) // quote it
          ?.join(delimiter) // comma-separated
    )
    ?.join("\r\n"); // rows starting on new lines
};

// Get the icon name for a file based on its MIME type
utils.getMimeIcon = (mime = "") => {
  if (mime.substring(0, 5) === "video") return "video";
  if (mime.substring(0, 5) === "audio") return "music";
  if (mime.substring(0, 5) === "image") return "jpg";
  if (mime.search("excel") >= 0 || mime.search("spreadsheet") >= 0)
    return "excel";
  if (mime.search("word") >= 0) return "word";
  if (mime === "application/gzip" || mime === "application/zip") return "zip";
  if (mime === "application/pdf") return "pdf";
  return "text";
};

// Intercept links to anchors, and scroll to them (prevent react router from navigating)

utils.interceptAnchorLinks = (containerSelector) => {
  setTimeout(() => {
    const container = document.querySelector(containerSelector) || document;
    const links = container.querySelectorAll("a");
    links.forEach((link) => {
      const href = link.getAttribute("href");
      // anchor links
      if (href && href.startsWith("#")) {
        link.addEventListener("click", (e) => {
          e.preventDefault();
          const element =
            document.querySelector(href) ||
            document.querySelector(`a[name="${href.replace("#", "")}"]`);
          if (element) {
            element.scrollIntoView({
              behavior: "smooth",
            });
          }
        });
      }
      // file downloads
      if (link.getAttribute("target") === "file-download") {
        link.addEventListener("click", (e) => {
          e.preventDefault();
          const fileName = link.getAttribute("href");
          utils.downloadFileSlug(fileName);
        });
      }
    });
  }, 1000);
};

utils.downloadFileSlug = (fileSlug) => {
  fetch(
    `${process.env.API_URL}/file/${fileSlug}?account=${utils.getClientRef()}`,
    {
      method: "GET",
      headers: {
        "x-auth-token": localStorage.getItem("authToken"),
      },
    }
  )
    .then(async (res) => {
      return res.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("target", "_blank");
      a.setAttribute("download", fileSlug);
      a.href = url;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(a.href);
      a.remove();
    })
    .catch((err) => {
      console.log(err);
    });
};

function expandHex(hex) {
  // Check if shorthand hex notation is used (e.g. "#fff" instead of "#ffffff")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });
  return hex;
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export default utils;
