import { lazy, Suspense } from "react";
import { Check, EyeOpen } from "akar-icons";
import { DateTime } from "luxon";

import SelectWrapper from "./selectWrapper";
import RadioFormInputs from "./radioFormInputs";
import Tickbox from "./tickbox";
import FormError from "./formError";
import IconPicker from "./iconPicker";
import RemainingLeave from "./remainingLeave";
import SVG from "./svg";
import ColourPicker from "./colourPicker";
import AbsenceTypePicker from "./absenceTypePicker";
import PeriodOverride from "./periodOverride";
import Notice from "./notice";
import useStore from "../hooks/useStore";
const RichText = lazy(() => import("./richText"));

const isReadOnly = (item, readOnlyFields) => {
  if (readOnlyFields?.includes(item.name)) return true;
};

const FormInput = ({
  id,
  item,
  register = () => {},
  setValue,
  getValues,
  validation = () => {},
  busy,
  autoFocus,
  focus,
  disabled,
  creatable,
  inputReRenderTrigger,
  doTriggerReRender,
  clickToEdit,
  clickToEditEditing = false,
  currentEditName,
  formItems,
  readOnlyFields,
  visibleOptions,
  onEdit,
  onEditFieldChange,
  onOptionToggle,
}) => {
  const subdomain = useStore((state) => state.subdomain);
  if (subdomain === "app") autofocus = false;
  if (!item) return null;
  if (clickToEdit && (item.type === "heading" || item.type === "section")) {
    return <h2>{item.label}</h2>;
  }
  if (clickToEdit && item.name !== currentEditName) {
    const formItem =
      formItems && formItems?.find((formItem) => formItem.name === item.name);
    const displayValue = () => {
      let currentValue = getValues(item.name);
      switch (item.type) {
        case "number":
          currentValue = parseFloat(currentValue);
          if (isNaN(currentValue)) {
            return "";
          }
          return item.currency ? `£ ${currentValue}` : currentValue;

        case "checkbox":
          return currentValue ? <Check /> : "";

        case "date":
          if (currentValue) {
            const date = DateTime.fromISO(currentValue?.substring(0, 10));
            return date.toFormat("dd/MM/yyyy");
          }
          return "-";

        case "select":
        case "multiCheckbox":
          if (!Array.isArray(currentValue)) {
            const findOption = item.options?.find(
              (option) => option.value === currentValue
            );
            if (findOption) {
              return (
                findOption.label || findOption.value || findOption.toString()
              );
            }
            return currentValue?.label || currentValue?.value || currentValue;
          }
          const values = currentValue.map((value) => {
            const outputValue = value?.label || value?.value || value;
            return outputValue ? (
              <span className="select-preview-option">{outputValue}</span>
            ) : (
              <></>
            );
          });
          return <>{values.map((value) => value)}</>;
      }
      if (typeof item.obfuscate === "function") {
        const obfuscateValue = currentValue || item.obfuscateDefault;
        const obfuscatedValue = item.obfuscate(obfuscateValue);
        if (currentValue && obfuscatedValue !== currentValue) {
          return (
            <>
              {obfuscatedValue} <EyeOpen />
            </>
          );
        }
        return obfuscatedValue;
      }
      return currentValue;
    };
    const canEditField = !!(
      formItem &&
      formItem.type !== "displayOnly" &&
      formItem.type !== "heading" &&
      formItem.type !== "section" &&
      formItem.type !== "notice" &&
      formItem.type !== "error" &&
      !isReadOnly(formItem, readOnlyFields)
    );

    // if these values don't exist in the fetch result, initialise them
    // with dafaults to prevent 'unsaved changes' warnings
    if (formItem?.name) {
      let currentValue = getValues ? getValues(formItem.name) : "";
      if (
        typeof currentValue === "undefined" ||
        (formItem.name === "employment--regularDays" &&
          (currentValue === null || currentValue.length === 0))
      ) {
        let defaultValue = "";
        if (formItem.type === "checkbox") defaultValue = false;
        if (formItem.name === "employment--regularDays") {
          defaultValue = [
            null,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          ];
        }

        setValue(formItem.name, defaultValue);
      }
    }

    return (
      <div
        onClick={() => {
          if (canEditField) onEdit(formItem.name);
        }}
        className={
          canEditField
            ? "form--click-to-edit-input"
            : "form--click-to-edit-input form--click-to-edit-input__cannot-edit"
        }
      >
        {displayValue()}
      </div>
    );
  }

  const itemDefault =
    typeof item.default !== "undefined" ? item.default : item.value;
  const defaultValue = isNaN(itemDefault) ? "" : itemDefault;

  const doCheckForEnter = (e, allowDefaultAction = false) => {
    const isDate = item.type === "date";
    // check enter has been pressed, and not shift and enter
    if (!e.shiftKey && (e.key === "Enter" || (e.key === "Tab" && !isDate))) {
      if (!allowDefaultAction) {
        e.preventDefault();
        e.stopPropagation();
        e.target?.blur();
      }
      setTimeout(() => {
        // submit the form
        const form = e.target?.form;
        form?.requestSubmit();
      }, 10);
    }
  };

  const doChangeAffect = (item, value) => {
    if (!item.onChangeAffect) {
      return;
    }
    item.onChangeAffect.map((affect) => {
      if (affect?.name && affect?.calc) {
        const affectedValue = affect.calc(item.name, value, getValues);
        if (affectedValue) setValue(affect?.name, affectedValue);
      }
      doTriggerReRender();
    });
  };

  switch (item?.type) {
    case "hidden":
      return (
        <input
          type="hidden"
          name={item.name}
          value={item.value}
          {...register(item.name)}
        />
      );
    case "checkbox":
      return (
        <div className="form-input-flex-pair">
          <Tickbox
            id={`sidebar-form-${id}`}
            setValue={setValue}
            name={item.name}
            checked={defaultValue || false}
            required={item.required}
            disabled={disabled}
            onChange={(val, e) => {
              const checked = e.target.checked;
              if (item.onChangeAffect) {
                item.onChangeAffect.map((affect) => {
                  if (affect?.name && affect?.calc) {
                    affect.calc(item.name, checked, getValues);
                  }
                });
              }
              onOptionToggle && onOptionToggle(e, val);
            }}
          />

          <input
            type="hidden"
            name={item.name}
            {...register(item.name, {
              required: {
                value: item.required ? true : false,
                message: `Please tick: '${item.label}'`,
              },
              ...validation(item.validate || item.type),
            })}
          />
          <label htmlFor={`sidebar-form-${id}`}>
            {item.label}
            {item.required && (
              <SVG src="asterisk" className="label--required-marker" />
            )}
          </label>
        </div>
      );

    case "multiCheckbox":
      if (item.options?.length <= 0) return null;
      const currentValues = getValues ? getValues(item.name) : "";
      return (
        <>
          <FocusGrabber enabled={clickToEditEditing} />
          {item.options?.map((option, key) => {
            const optionValue =
              typeof option === "string" ? option : option?.value;
            return (
              <div key={key} className="form-input-flex-pair">
                <Tickbox
                  id={`sidebar-form-${id}-${key}`}
                  register={register}
                  setValue={(_name, value) => {
                    const currentValues = getValues ? getValues(item.name) : "";
                    const newValues = currentValues ? [...currentValues] : [];
                    const index = newValues?.findIndex(
                      (item) => item?.value === key + 1
                    );
                    if (value === true && index === -1) {
                      newValues.push({
                        value: key + 1,
                        label: optionValue || "",
                      });
                    }
                    if (value === false && index > -1) {
                      newValues.splice(index, 1);
                    }
                    if (setValue) setValue(item.name, newValues);
                  }}
                  name={`${item.name}[${key}]`}
                  checked={
                    currentValues &&
                    Array.isArray(currentValues) &&
                    !!currentValues?.find((v) => v?.value === key + 1)
                  }
                  disabled={disabled}
                />
                <label htmlFor={`sidebar-form-${id}-${key}`}>
                  {optionValue || ""}
                </label>
              </div>
            );
          })}
        </>
      );
    case "radio":
      const radioOptions =
        Array.isArray(item.options) &&
        item.options.length > 0 &&
        item.options.map((option, key) => {
          if (typeof option === "string")
            return {
              value: option,
              label: option,
            };
          return {
            value: option?.key || option?.value,
            label: option?.value,
          };
        });
      const itemValue =
        (getValues && getValues(item.name)) || item.value?.value || item.value;

      const radioOnChange = (e, value) => {
        onOptionToggle(e);
        doChangeAffect(item, value);
      };

      return (
        <RadioFormInputs
          name={item.name}
          value={itemValue}
          options={radioOptions}
          label={item.label}
          required={item.required}
          disabled={disabled}
          setValue={setValue}
          register={register}
          validation={validation}
          onChange={radioOnChange}
        />
      );

    case "select":
      const creatable = !!item?.creatable;
      const filtered = visibleOptions?.find(
        (filter) => filter.field === item.name
      );
      const options =
        (
          item?.options &&
          Array.isArray(item?.options) &&
          item.options.map((option) => {
            // email recipient selection, based on name being 'recipinet'
            if (item.label?.toLowerCase() === "recipient") {
              // remove anything in square brackets
              const regex = /\[.*?\]/g;
              if (typeof option === "string") {
                return {
                  value: option,
                  label: option.replace(regex, ""),
                };
              }
              return {
                value: option?.value,
                label: option?.value?.replace(regex, ""),
              };
            }
            if (typeof option === "string")
              return {
                value: option,
                label: option,
              };
            return option;
          })
        )
          ?.filter((option) => {
            // Check if the options are to be filtered (i.e. some hidden)
            if (filtered && Array.isArray(filtered.options)) {
              return !!filtered.options.includes(option.value);
            } else return true;
          })
          ?.filter((option, i, self) => {
            // unique options only
            return self.findIndex((o) => o.value === option.value) === i;
          }) || [];
      return (
        <SelectWrapper
          name={item.name}
          required={item.required}
          aria-required={item.required}
          isDisabled={busy || disabled}
          options={options}
          creatable={creatable}
          value={getValues && getValues(item.name)}
          autoFocus={!!(autoFocus && (id === "0-0" || focus || item.autoFocus))}
          setValue={setValue}
          renderTrigger={inputReRenderTrigger}
          placeholder={item.placeholder}
          isMulti={!!item.multi}
          onKeyDown={(e) => {
            doCheckForEnter(e, true);
          }}
          onEditFieldChange={(_e, value) => {
            if (onOptionToggle && typeof onOptionToggle === "function")
              onOptionToggle();

            doChangeAffect(item, value);

            if (onEditFieldChange) {
              onEditFieldChange(_e, value);
            }
          }}
        />
      );
      break;
    case "textarea":
      return (
        <textarea
          id={`sidebar-form-${id}`}
          type={item.type === "password" ? item.type : "text"}
          placeholder={null}
          required={item.required}
          aria-required={item.required}
          disabled={busy || disabled}
          defaultValue={defaultValue}
          onKeyDown={doCheckForEnter}
          {...register(item.name, {
            required: {
              value: item.required ? true : false,
              message: `Please enter ${item.label?.toLowerCase()}`,
            },
            ...validation(
              item.validate !== "undefined" ? item.validate : item.type
            ),
          })}
          autoFocus={!!(autoFocus && (id === "0-0" || focus || item.autoFocus))}
        />
      );
    case "richtext":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <RichText
            name={item.name}
            content={item.value}
            register={register}
            editorRef={item.inputRef}
            setValue={setValue}
            disabled={disabled}
            onCreate={item.onCreate}
            {...register(item.name, {
              required: {
                value: item.required ? true : false,
                message: `Please enter ${
                  item.label?.toLowerCase() || item.labelHidden?.toLowerCase()
                }`,
              },
              ...validation(
                item.validate !== "undefined" ? item.validate : item.type
              ),
            })}
            autoFocus={
              !!(autoFocus && (id === "0-0" || focus || item.autoFocus))
            }
          />
        </Suspense>
      );
    case "absenceType":
      return (
        <AbsenceTypePicker
          name={item.name}
          setValue={setValue}
          value={item.value}
          disabled={disabled}
          register={register}
          validation={validation}
        />
      );

    case "icon":
      return (
        <IconPicker
          icon={item.value}
          name={item.name}
          iconStyle={{
            width: "5rem",
            height: "5rem",
            maxWidth: "5rem",
            maxHeight: "5rem",
          }}
          register={register}
          setValue={setValue}
          disabled={disabled}
        />
        // <input type="hidden" {...register(name)} />
      );
    case "colour":
      return (
        <ColourPicker
          name={item.name}
          colour={item.value || item.colour}
          register={register}
          setValue={setValue}
          disabled={busy || disabled}
        />
      );

    case "periodOverride":
      return (
        <PeriodOverride
          id={`sidebar-form-${id}`}
          disabled={busy || disabled}
          value={getValues(item.name) || item.value}
          {...register(item.name)}
          onChange={(value) => {
            const currentValue = getValues(item.name);
            setValue(item.name, {
              ...currentValue,
              value,
            });
          }}
        />
      );

    case "number":
      return (
        <div className={item.currency ? "input-number-prefix-container" : ""}>
          <input
            id={`sidebar-form-${id}`}
            type="number"
            min="0"
            max={item.max}
            step={item.step || 1}
            required={item.required}
            aria-required={item.required}
            disabled={busy || disabled}
            defaultValue={parseFloat(defaultValue) || ""}
            {...register(item.name, {
              valueAsNumber: true,
              required: {
                value: item.required ? true : false,
                message: `Please enter ${item?.label?.toLowerCase()}`,
              },
              ...validation(item.validate || item.type),
            })}
            onKeyDown={doCheckForEnter}
            onChange={(e) => {
              if (item.onChangeAffect) {
                item.onChangeAffect.map((affect) => {
                  if (affect?.name && affect?.calc) {
                    const watchValue = parseFloat(e.target.value);
                    const affectedValue = affect.calc(
                      item.name,
                      watchValue,
                      getValues
                    );
                    setValue(affect?.name, affectedValue);
                  }
                });
              }
              if (item.reCalcFields?.length) {
                setValue(item.name, parseFloat(e.target.value));
                item.reCalcFields.map((fieldName) => {
                  if (fieldName) {
                    const field = formItems.find(
                      (formItem) => formItem.name === fieldName
                    );
                    if (field?.onChangeAffect) {
                      field.onChangeAffect.map((affect) => {
                        if (affect?.name && affect?.calc) {
                          const watchValue = getValues(field.name);
                          const affectedValue = affect.calc(
                            fieldName,
                            watchValue,
                            getValues
                          );
                          setValue(affect?.name, affectedValue);
                        }
                      });
                    }
                  }
                });
              }
            }}
            autoFocus={
              !!(autoFocus && (id === "0-0" || focus || item.autoFocus))
            }
          />
        </div>
      );
    case "date":
      return (
        <input
          id={`sidebar-form-${id}`}
          type="date"
          required={item.required}
          aria-required={item.required}
          disabled={busy || disabled}
          defaultValue={defaultValue}
          {...register(item.name, {
            required: {
              value: item.required ? true : false,
              message: `Please enter ${item?.label?.toLowerCase()}`,
            },
            onBlur: (e) =>
              item.onChange ? item.onChange(e.target.value) : null,
            ...validation(item.validate || item.type),
          })}
          autoFocus={!!(autoFocus && (id === "0-0" || focus || item.autoFocus))}
          onChange={(e) => {
            if (item.onChangeAffect) {
              item.onChangeAffect.map((affect) => {
                if (affect?.name && affect?.calc) {
                  const watchValue = e.target.value;
                  const affectedValue = affect.calc(
                    item.name,
                    watchValue,
                    getValues
                  );
                  setValue(affect?.name, affectedValue);
                }
              });
            }
          }}
          onKeyDown={doCheckForEnter}
        />
      );
    case "remainingLeave":
      return (
        <RemainingLeave
          userId={getValues(item.name) || item.value}
          {...register(item.name)}
        />
      );

    case "error":
      return <FormError content={item.value} visible={item.display} />;
    case "displayOnly":
      return <>{defaultValue}</>;
    case "notice":
      return <Notice message={item.value} />;
    case "heading":
      return <h2>{item.label}</h2>;
    case "section":
    case "page":
      return item.label ? <h3>{item.label}</h3> : null;
    case "button":
      return (
        <button
          className="button"
          type="button"
          disabled={busy || disabled}
          onClick={() => {
            item.action(getValues());
          }}
        >
          {!busy ? item.label : item.labelBusy}
        </button>
      );
    case "buttons":
      return (
        <div className="form-input-flex form-input-flex__tight form-input-flex__tighter">
          {item.buttons.map((button, key) => (
            <button
              key={key}
              className="button button__invert"
              type="button"
              disabled={busy || disabled}
              onClick={() => {
                button.onClick(getValues());
              }}
            >
              {!busy ? button.label : button.labelBusy}
            </button>
          ))}
        </div>
      );
    case "file":
      return (
        <input
          id={`sidebar-form-${id}`}
          type="file"
          required={item.required}
          aria-required={item.required}
          disabled={busy || disabled}
          {...register(item.name, {
            required: {
              value: item.required ? true : false,
              message: `Please upload a file`,
            },
            ...validation(item.validate || item.type),
          })}
        />
      );
    case "text":
    default:
      return (
        <input
          id={`sidebar-form-${id}`}
          type={item.type === "password" ? item.type : "text"}
          placeholder={null}
          required={item.required}
          aria-required={item.required}
          disabled={busy || disabled}
          defaultValue={defaultValue}
          maxLength={item.maxlength}
          autoFocus={!!(autoFocus && (id === "0-0" || focus || item.autoFocus))}
          onKeyDown={doCheckForEnter}
          {...register(item.name, {
            required: {
              value: item.required ? true : false,
              message: `Please enter ${item?.label?.toLowerCase()}`,
            },
            ...validation(item.validate || item.type),
          })}
        />
      );
  }
};
const FocusGrabber = ({ enabled = false }) => {
  // This allows the 'press enter to advance' functionality when 'click to edit'
  // mode is enabled. It grabs the focus, so enter can be pressed to submit the form,
  // triggering the onSubmit event
  return (
    <input
      autoFocus={!!enabled}
      aria-hidden="true"
      style={{
        width: 0,
        height: 0,
        position: "absolute",
        opacity: 0,
        pointerEvents: "none",
      }}
      value={enabled?.toString()}
      onChange={() => {}}
    />
  );
};

export default FormInput;
