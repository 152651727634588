import { forwardRef, useEffect, useState, useRef, Fragment } from "react";
import { useForm, Controller, set } from "react-hook-form";

import { CirclePlus } from "akar-icons";
import inspect from "object-inspect";

import SubmitButton from "./submitButton";
import FormError from "./formError";
import Skeleton from "./skeleton";
import Notice from "./notice";
import FormInput from "./formInput";
import EditableText from "./editableText";
import FormEditIcons from "../pages/components/formEditIcons";
import Icon from "../common/icon";
import SVG from "./svg";

import { useCreateFormField } from "../hooks/fetch/useFetchForms";
import useInterval from "../hooks/useInterval";
import useStore from "../hooks/useStore";
import useFocusComponent from "../hooks/useFocusComponent";

const scrollToTop = () => {
  setTimeout(() => {
    const elementsToScroll = document.querySelectorAll(
      ".main-container, .main-body-scrollable, .app-container, .main-content--scrollbox, .simplebar-content-wrapper"
    );

    elementsToScroll.forEach((element) => {
      element?.scrollTo(0, 0);
    });

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, 100);
};

const Form = ({
  sections: sectionsInit,
  readOnlyFields,
  hideFields,
  id,
  notice,
  className,
  autoFocus = true,
  disabled = false,
  edit = false,
  busy = false,
  loading = false,
  registerCallback,
  registerFormRef,
  dependencies = null,
  clickToEdit = false,
  ignorePaging = true,
  modalMessage = null,
  actionButtons,
  actionButtonsHeading,
  warnUnsavedChanges = false,
  onSubmit,
  onBusy = null,
  onCompleted = null,
  onChangePage = null,
}) => {
  // paging, for forms with field of type 'page' (page divider)
  const pages = [];
  if (Array.isArray(sectionsInit) && !ignorePaging) {
    let currentPage = [];
    sectionsInit.map((section) => {
      if (section?.items?.[0]?.type !== "page") {
        currentPage.push(section);
      } else {
        pages.push(currentPage);
        currentPage = [];
      }
    });
    if (currentPage.length > 0) pages.push(currentPage);
  }
  if (ignorePaging) pages.push(sectionsInit);
  sectionsInit = pages[0];
  const hasPaging = !!!ignorePaging && !!(pages.length > 1);

  const [formPage, setFormPage] = useState(0);
  const [sections, setSections] = useState(sectionsInit);
  const hasUnsavedChanges = useStore((state) => state.hasUnsavedChanges);
  const setHasUnsavedChanges = useStore((state) => state.setHasUnsavedChanges);
  const hasJustSaved = useStore((state) => state.hasJustSaved);
  const setHasJustSaved = useStore((state) => state.setHasJustSaved);
  const pathParts = useStore((state) => state.getPathParts());
  const currentUser = useStore((state) => state.currentUser);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const adminEditingAccount = pathParts[0];
  const { isHR } = currentUser;

  useEffect(() => {
    setSections(pages[formPage] || sectionsInit);
  }, [formPage]);
  useEffect(() => {
    setFormPage(0);
  }, [id]);

  const { mutate: createField, isLoading: isCreating } = useCreateFormField({
    account: adminEditingAccount,
    callback: doInsertCallback,
  });

  const { ref: focusedRef, setFocused } = useFocusComponent(() => {
    setCurrentEditName();
  });

  const reactHookForm = useForm({
    mode: "onBlur",
  });

  if (registerCallback)
    registerCallback((_e, mutateData) => {
      // when submitting the form via callback ref, we can merge in additional data
      mutateData = mutateData || {};
      reactHookForm.handleSubmit((data) => {
        const formValues = { ...data, ...mutateData };
        onSubmit(formValues);
        setCurrentEditName();
      })();
    });
  if (registerFormRef) registerFormRef(reactHookForm);

  const initialValuesJSON = useRef();
  if (hasJustSaved && !disabled) {
    setHasUnsavedChanges(false);
    setHasJustSaved(false);
    initialValuesJSON.current = inspect(reactHookForm.getValues());
  }

  // force re-render of react-select when data changes
  const [inputReRenderTrigger, setInputRerenderTrigger] = useState(Math.random);
  const [formItems, setFormItems] = useState();
  const [currentEditName, setCurrentEditName] = useState();
  const [hiddenFields, setHiddenFields] = useState([]);
  const [visibleOptions, setVisibleOptions] = useState([]);

  const currentHiddenFieldsRef = useRef(hiddenFields);
  useEffect(() => {
    currentHiddenFieldsRef.current = hiddenFields;
  }, [hiddenFields]);

  const doTriggerReRender = () => {
    setInputRerenderTrigger(Math.random);
  };

  const doCheckFormHasChanged = () => {
    if (!warnUnsavedChanges || disabled) return;
    const current = inspect(reactHookForm.getValues());
    const initial = initialValuesJSON.current;
    if (initial) {
      if (current !== initial && !hasUnsavedChanges) {
        return setHasUnsavedChanges(true);
      }
      if (current === initial && hasUnsavedChanges) {
        return setHasUnsavedChanges(false);
      }
    }
  };
  useInterval(doCheckFormHasChanged, 1000);

  const doInitialiseForm = (newSections) => {
    const currentFormItems = [];
    let formSections = newSections || sections;
    if (newSections) setSections(newSections);
    if (!disabled) setHasUnsavedChanges(false);
    initialValuesJSON.current = null;
    setInputRerenderTrigger(Math.random);
    reactHookForm.reset();
    setTimeout(() => {
      initialValuesJSON.current = inspect(reactHookForm.getValues());
    }, 100);
    formSections?.map((section) => {
      section.items
        ?.filter((item) => {
          if (!hideFields || !hideFields.includes(item.name)) return true;
        })
        ?.map((item) => {
          let defaultValue =
            typeof item.default === "undefined" ? item.value : item.default;

          if (item.type === "icon") {
            reactHookForm.setValue(item.name, item.value);
            currentFormItems.push({
              ...item,
            });
          } else if (item.type === "number") {
            const value = parseFloat(defaultValue);
            reactHookForm.setValue(item.name, value);
            item.value = value;
            currentFormItems.push({
              ...item,
            });
          } else if (item.type === "select") {
            const options =
              (Array.isArray(item?.options) &&
                item.options.map((option) => {
                  if (typeof option === "string")
                    return {
                      value: option,
                      label: option,
                    };
                  return (option?.value && option) || [];
                })) ||
              [];
            parseValue = (value) => {
              if (Array.isArray(value)) {
                return value.map((value) => {
                  if (typeof value === "string" || typeof value === "number") {
                    return (
                      options?.find((option) => option.value == value) ||
                      options[0]
                    );
                  }
                  return value;
                });
              }
              if (typeof value === "string") {
                return (
                  options?.find((option) => option.label == value) || value
                );
              }
              if (typeof value === "number") {
                return (
                  options?.find((option) => option.value == value) || value
                );
              }
              return value;
            };

            defaultValue = (defaultValue && parseValue(defaultValue)) || null;

            if (!reactHookForm.getValues(item.name)) {
              reactHookForm.setValue(item.name, defaultValue);
            }
            currentFormItems.push({
              ...item,
              value: defaultValue?.label,
            });
          } else if (item.type === "multiCheckbox") {
            if (defaultValue) {
              reactHookForm.setValue(item.name, defaultValue);
            } else {
              reactHookForm.setValue(item.name, []);
            }
            currentFormItems.push({
              ...item,
            });
          } else {
            if (item.default !== undefined && item.default !== null) {
              reactHookForm.setValue(item.name, item.default);
            } else if (item.value !== undefined && item.value !== null) {
              reactHookForm.setValue(item.name, item.value);
            }
            currentFormItems.push({
              ...item,
            });
          }
        });
    });
    setFormItems(currentFormItems);
  };

  // When using click to edit, pressing enter should run doSubmit, which advances
  // to the next field
  const doOnKeyDown = (e) => {
    if (!clickToEdit) return;
    const { key, target } = e;
    // is escape key pressed
    if (key === "Escape") {
      // stop editing
      return setCurrentEditName();
    }
    // Check if an input or textarea is focused
    if (["INPUT", "TEXTAREA"].includes(target.tagName)) {
      return;
    }
    if (key === "Enter" || key === "Tab") {
      doSubmit();
    }
  };

  useEffect(() => {
    doInitialiseForm();
  }, [id]);

  // Use a ref to keep track of whether the component is mounted
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    // Remove keydown event listener if the component is unmounted
    return () => {
      if (isMounted.current) {
        document.body.removeEventListener("keydown", doOnKeyDown);
      }
    };
  }, []);

  useEffect(() => {
    if (currentEditName) {
      document.body.addEventListener("keydown", doOnKeyDown);
    }
    return () => {
      document.body.removeEventListener("keydown", doOnKeyDown);
    };
  }, [currentEditName]);

  useEffect(() => {
    doInitialiseForm(sectionsInit);
  }, [inspect(sectionsInit)]);

  const validation = (type) => {
    if (type && typeof type === "function") {
      const getValues = reactHookForm.getValues;
      return {
        validate: {
          custom: (value) => {
            return type(value, getValues);
          },
        },
      };
    }

    switch (type) {
      case "password":
        return {
          minLength: {
            value: 8,
            message: "Password must have at least 8 characters",
          },
          validate: {
            hasLower: (value) => (value.match(/^$|[a-z]+/) ? true : false),
            hasUpper: (value) => (value.match(/^$|[A-Z]+/) ? true : false),
            hasNumber: (value) => (value.match(/^$|[0-9]+/) ? true : false),
            hasSymbol: (value) =>
              value.match(/^$|[^a-zA-Z0-9]+/) ? true : false,
          },
        };

      case "url":
        return {
          validate: {
            isURL: (value) => {
              const isValidURL =
                /^(http:\/\/|https:\/\/)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}(:[0-9]{1,5})?(\/.*)?$/.test(
                  value
                );
              return (
                !value ||
                isValidURL ||
                "Invalid URL. It should start with http:// or https://"
              );
            },
          },
        };

      case "richtext":
        return {
          validate: {
            isContent: (value) => {
              const parsedContent = value?.replace(/<[^>]*>|&nbsp;|\s/g, "");
              const isEmpty = !parsedContent || parsedContent === "";
              return !isEmpty || "Please enter a message";
            },
          },
        };

      default:
        return null;
    }
  };

  const formatLineBreaks = (text) => {
    if (text) {
      return text.split("\n").map((item, key) => {
        return (
          <Fragment key={key}>
            {item}
            <br />
          </Fragment>
        );
      });
    }
    return null;
  };

  const getError = (field) => {
    if (reactHookForm.formState.errors[field]?.type === "hasLower")
      return "Password must have at least 1 lowercase character (a-z)";
    if (reactHookForm.formState.errors[field]?.type === "hasUpper")
      return "Password must have at least 1 uppercase character (A-Z)";
    if (reactHookForm.formState.errors[field]?.type === "hasNumber")
      return "Password must have at least 1 number (0-9)";
    if (reactHookForm.formState.errors[field]?.type === "hasSymbol")
      return "Password must have at least 1 symbol (e.g. punctuation)";
    return reactHookForm.formState.errors[field]?.message;
  };

  const loadingArr = [];
  for (let r = 0; r < 20; r++) {
    loadingArr.push("");
  }

  const doEditInput = (itemName) => {
    setCurrentEditName(itemName);
    console.log("now editing " + itemName);
    setFocused();
  };
  const doSubmit = (e, value) => {
    if (clickToEdit) {
      const currentField = formItems.findIndex(
        (field) => field.name === currentEditName
      );
      formItems[currentField].default = value || (e && e.target?.value);
      setCurrentEditName();
      setTimeout(() => doFocusNextItem(e), 10);
    }
    if (e?.preventDefault) e?.preventDefault();
    return false;
  };
  const doFocusNextItem = (e) => {
    if (clickToEdit) {
      e?.preventDefault();
      let currentField = formItems.findIndex(
        (field) => field.name === currentEditName
      );
      const lastIndex = formItems.length - 1;
      let nextField =
        lastIndex === currentField ? null : formItems[currentField + 1];
      setTimeout(() => {
        let isEditable = isEditableField(nextField);
        while (!isEditable && nextField) {
          currentField++;
          nextField =
            lastIndex === currentField ? null : formItems[currentField + 1];
          isEditable = isEditableField(nextField);
        }
        if (nextField && isEditable) {
          return setTimeout(() => {
            doEditInput(nextField.name);
          }, 10);
        }
        doEditInput();
      }, 150);
    }
  };

  const formClassName = className ? `form ${className}` : "form";
  const getLabel = (item, key) => {
    if (
      item.label &&
      (item.type !== "checkbox" || clickToEdit) &&
      item.type !== "heading" &&
      item.type !== "section" &&
      item.type !== "page" &&
      item.type !== "button"
    ) {
      const editClass = edit ? "label--edit" : "";

      return (
        <div className="form-label-container">
          <label htmlFor={`sidebar-form-${key}`} className={editClass}>
            {edit && 1 == 2 ? (
              <EditableText value={item.label} />
            ) : (
              <>{item.labelComponent || item.label}</>
            )}
            {item.required && (
              <SVG src="asterisk" className="label--required-marker" />
            )}
          </label>
          {!clickToEdit && item.actionIcons && (
            <div className="title-action-buttons">
              {item.actionIcons.map((actionIcon, key) => (
                <button
                  className="button-link"
                  aria-label={actionIcon.label}
                  title={actionIcon.label}
                  disabled={actionIcon.disabled}
                  key={key}
                  onClick={(e) => {
                    const formValues = reactHookForm.getValues();
                    const formValue = formValues[item.name];
                    actionIcon.action(
                      formValue,
                      e,
                      reactHookForm.getValues,
                      reactHookForm.setValue
                    );
                  }}
                >
                  <Icon type="akar" strokeWidth={2} src={actionIcon.icon} />
                </button>
              ))}
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  const isEditableField = (item) => {
    const currentHiddenFields = currentHiddenFieldsRef.current;
    if (!item) return false;
    if (
      currentHiddenFields?.length > 0 &&
      currentHiddenFields.includes(item.name)
    ) {
      return false;
    }
    if (
      item.type !== "displayOnly" &&
      item.type !== "heading" &&
      (!Array.isArray(readOnlyFields) || !readOnlyFields.includes(item.name))
    ) {
      return true;
    }
    return false;
  };

  const canEdit = (item) => {
    const canEdit = !!(
      clickToEdit &&
      (!currentEditName || item.name === currentEditName) &&
      isEditableField(item)
    );
    return canEdit;
  };
  const itemClass = (item) => {
    if (clickToEdit) {
      return item?.name === currentEditName && canEdit(item)
        ? "form-item form--field-currently-editing"
        : "form-item";
    }
    return "form-item";
  };
  const itemEditClass = (item) => {
    return !canEdit(item)
      ? "form--click-to-edit form--click-to-edit__disabled"
      : "form--click-to-edit";
  };

  // do this when any input with options (e.g. select) is changed
  const doOptionToggle = (e, currentValues) => {
    // show or hide any fields based on value of dependency fields
    if (dependencies) {
      const formValues = reactHookForm.getValues;
      const newHiddenFields = [];
      const newVisibleOptions = [];
      for (dependency in dependencies) {
        if (
          formValues().hasOwnProperty(dependency) &&
          Array.isArray(dependencies[dependency].dependents)
        ) {
          const currentHiddenFields = [];
          const currentVisibleOptions = [];
          for (dependent of dependencies[dependency].dependents) {
            const fieldWatch = formValues()[dependency];
            let showField = true;
            let changeVisibleOptions = false;
            if (typeof dependencies[dependency].condition === "function") {
              if (
                !dependencies[dependency].condition(fieldWatch, dependent, e)
              ) {
                showField = false;
              }
            } else {
              switch (dependencies[dependency].condition) {
                case "filterEqual":
                  const allOptions = dependencies[dependency].values;
                  changeVisibleOptions = allOptions[fieldWatch?.value];
                  break;
                case "notEqual":
                  if (
                    Array.isArray(dependencies[dependency].values) &&
                    dependencies[dependency].values.findIndex((option) => {
                      return !!(
                        option === fieldWatch || option === fieldWatch?.value
                      );
                    }) > -1
                  ) {
                    showField = false;
                  }
                  break;
              }
            }
            if (changeVisibleOptions !== false) {
              const currentSelectedFilter = [...visibleOptions].find(
                (field) => field.field === dependent
              );
              if (
                currentSelectedFilter &&
                currentSelectedFilter.options !== changeVisibleOptions
              ) {
                reactHookForm.setValue(dependent, []);
              }
              currentVisibleOptions.push({
                field: dependent,
                options: changeVisibleOptions,
              });
            }
            if (!showField) {
              currentHiddenFields.push(dependent);
            }
          }
          newHiddenFields.push(...currentHiddenFields);
          newVisibleOptions.push(...currentVisibleOptions);
        }
      }
      setHiddenFields(newHiddenFields);
      setVisibleOptions(newVisibleOptions);
    }
  };
  useEffect(() => {
    // do not put this in a timeout - it breaks stuff
    doOptionToggle();
  }, []);

  const doActionButton = (action) => {
    if (action?.action) {
      if (action?.submit) {
        return reactHookForm.handleSubmit(action?.action)();
      }
      return action?.action(id);
    }
  };

  const isFieldHidden = (item) => {
    if (
      (hiddenFields?.length > 0 &&
        hiddenFields.findIndex((field) => field === item.name) > -1) ||
      item.type === "hidden" ||
      item.display === false
    ) {
      return true;
    }
    return false;
  };

  const sectionClass = clickToEdit
    ? "form-section sidebar-form--section form-section__click-to-edit"
    : "form-section sidebar-form--section";

  const actionButtonsClass = busy
    ? "button button__invert button__disabled"
    : "button button__invert";

  const getInputProps = (item, key, itemKey) => {
    return {
      id: `${key}-${itemKey}`,
      itemKey,
      item,
      validation,
      busy,
      ...reactHookForm,
      Controller,
      inputReRenderTrigger,
      autoFocus,
      disabled: disabled || item.disabled || edit,
      visibleOptions,
      formItems,
      readOnlyFields,
      doTriggerReRender,
      onOptionToggle: (e) =>
        setTimeout((e) => {
          console.log("option toggle 2");
          doOptionToggle(e);
        }, 100),
    };
  };

  let numberOfFields = 0;

  const doInsertField = () => {
    const newField = {
      label: "New Field",
      type: "text",
      required: false,
    };
    createField({ id, ...newField });
  };
  function doInsertCallback(data) {
    const insertedField = Array.isArray(data?.data) && data?.data[0];
    if (insertedField) {
      insertedField.name = insertedField.fieldId;
      setSections([{ items: [{ ...insertedField }] }]);
    }
  }

  function isItemPendingApproval(item) {
    // for employee db - check if field is pending approval
    if (!item.pendingApproval) return false;
    // don't return if user is current logged in user
    // i.e. users cannot approve their own changes
    if (item.pendingApproval !== undefined && id && id !== currentUser?.id)
      return "true";
    return false;
  }

  const getFilteredFormSections = (sections) => {
    if (!Array.isArray(sections)) return [];
    const filtered = sections?.filter((field) => {
      if (
        (!hideFields || !hideFields.includes(field.name)) &&
        !isFieldHidden(field)
      ) {
        return true;
      }
    });
    return filtered;
  };
  const getIsSection = (items) => {
    if (items?.length !== 1) return false;
    if (items[0].type === "section" || items[0].type === "page") return true;
    return false;
  };
  return (
    <>
      {notice && <Notice message={notice} />}

      {actionButtons && (
        <div
          className="drawer-action-buttons"
          data-is-disabled={busy || disabled}
        >
          {actionButtonsHeading && (
            <div className="drawer-action-buttons-heading">
              {actionButtonsHeading}
            </div>
          )}
          {actionButtons.map((actionButton, key) => (
            <button
              key={key}
              className={actionButtonsClass}
              onClick={() => {
                doActionButton(actionButton);
              }}
              disabled={busy || disabled}
            >
              {actionButton.label}
            </button>
          ))}
        </div>
      )}
      <form
        className={formClassName}
        data-is-disabled={disabled}
        autoComplete="off"
        onSubmit={doSubmit}
      >
        <input
          type="hidden"
          name="id"
          defaultValue={id}
          {...reactHookForm.register("id")}
        />

        {loading
          ? loadingArr.map((row, key) => (
              <div key={key} className={sectionClass}>
                <Skeleton lines="2" />
              </div>
            ))
          : sections?.map((section, key) => (
              <Fragment key={`${key}`}>
                {getFilteredFormSections(section.items)?.length > 0 && (
                  <>
                    {section.heading && <h3>{section.heading}</h3>}
                    <div
                      className={sectionClass}
                      data-is-section={getIsSection(section.items)}
                    >
                      {getFilteredFormSections(section.items)?.map(
                        (item, itemKey) => {
                          if (item?.hidden) return null;
                          numberOfFields++;
                          return (
                            <Fragment key={`${key}-${itemKey}`}>
                              {!isFieldHidden(item) && (
                                <div
                                  className={itemClass(item)}
                                  data-pending-approval={isItemPendingApproval(
                                    item
                                  )}
                                  ref={
                                    canEdit(item) &&
                                    item.name === currentEditName
                                      ? focusedRef
                                      : null
                                  }
                                >
                                  {canEdit(item) &&
                                    !clickToEdit &&
                                    getLabel(item, `${key}-${itemKey}`)}

                                  <FormError
                                    content={getError(item.name)}
                                    visible={
                                      reactHookForm.formState.errors[item.name]
                                        ? true
                                        : false
                                    }
                                  ></FormError>

                                  {clickToEdit && (
                                    <>
                                      {item.name === currentEditName && (
                                        <>
                                          {getLabel(item, `${key}-${itemKey}`)}
                                          <FormInput
                                            {...getInputProps(
                                              item,
                                              key,
                                              itemKey
                                            )}
                                            focus={clickToEdit}
                                            clickToEditEditing={true}
                                            onEditFieldChange={doSubmit}
                                          />
                                        </>
                                      )}
                                      {item.name !== currentEditName && (
                                        <div className={itemEditClass(item)}>
                                          {getLabel(item, `${key}-${itemKey}`)}
                                          <FormInput
                                            {...getInputProps(
                                              item,
                                              key,
                                              itemKey
                                            )}
                                            content={getError(item.name)}
                                            visible={
                                              reactHookForm.formState.errors[
                                                item.name
                                              ]
                                                ? true
                                                : false
                                            }
                                            clickToEdit={clickToEdit}
                                            currentEditName={currentEditName}
                                            onEdit={doEditInput}
                                          />
                                        </div>
                                      )}
                                    </>
                                  )}

                                  {!clickToEdit && (
                                    <>
                                      {getLabel(item, `${key}-${itemKey}`)}
                                      <FormInput
                                        {...getInputProps(item, key, itemKey)}
                                        onEdit={doEditInput}
                                      />
                                    </>
                                  )}

                                  {item.caption && (
                                    <div>{formatLineBreaks(item.caption)}</div>
                                  )}
                                </div>
                              )}
                              {edit && (
                                <FormEditIcons
                                  fieldId={item.name}
                                  formId={id}
                                  formSections={sections}
                                  onSetSections={setSections}
                                  onBusy={onBusy}
                                  onCompleted={onCompleted}
                                />
                              )}
                            </Fragment>
                          );
                        }
                      )}
                    </div>
                  </>
                )}
              </Fragment>
            ))}
        {modalMessage && <FormModalMessage message={modalMessage} />}
      </form>

      {hasPaging && (
        <div className="paging">
          <button
            className="button-unstyled button-unstyled__link"
            disabled={formPage === 0}
            onClick={async () => {
              const valid = await reactHookForm.trigger();
              if (valid) {
                scrollToTop();
                setFormPage(formPage - 1);
              }
            }}
          >
            <SVG src="arrow-right" label="Go" className="icon" />
            <span className="paging--label">Previous</span>
          </button>
          <span></span>

          {formPage < pages?.length - 1 && (
            <button
              className="button-unstyled button-unstyled__link"
              onClick={async () => {
                const valid = await reactHookForm.trigger();
                if (valid) {
                  scrollToTop();
                  setFormPage(formPage + 1);
                }
              }}
            >
              <span className="paging--label">Next</span>
              <SVG src="arrow-right" label="Go" className="icon" />
            </button>
          )}

          {formPage >= pages?.length - 1 && (
            <SubmitButton
              disabled={isSubmitting}
              isForm={true}
              label={isSubmitting ? "Submitting..." : "Submit form"}
              onSubmit={async () => {
                const valid = await reactHookForm.trigger();
                if (valid) {
                  scrollToTop();
                  setIsSubmitting(true);
                  onSubmit(reactHookForm.getValues());
                }
              }}
            />
          )}
        </div>
      )}

      {numberOfFields === 0 && edit && (
        <div className="flex flex__center">
          <button
            class="button"
            onClick={doInsertField}
            aria-label="Insert first field into this form"
          >
            <CirclePlus /> Add a field
          </button>
        </div>
      )}
    </>
  );
};
const FormModalMessage = ({ message }) => {
  return <div className="form-modal-message">{message}</div>;
};

export default Form;
