import {
  useQuery,
  useQueryClient,
  useMutation,
  useIsMutating,
} from "react-query";
import set from "set-value";
import { request } from "../../../utilities/fetch.js";

const transformUserProfile = (data) => {
  let newData = {};
  // any select inputs will be passed as object, so
  // need to extract value
  const selects = [
    "details--maritalStatus",
    "details--gender",
    "employment--status",
    "employment--noticePeriodEmployee",
    "employment--noticePeriodCompany",
    "employment--salaryHourly",
    "employment--type",
    "employment--lineManagers",
    "employment--endDetails",
    "employment--placeOfWork",
    "employment--department",
    "employment--location",
    "accessLevel",
    "groups",
  ];
  for (const property in data) {
    let dataValue = data[property];
    if (selects.indexOf(property) > -1) {
      if (Array.isArray(dataValue)) {
        dataValue = dataValue.map((item) => {
          return item?.value;
        });
      } else if (dataValue?.value) {
        dataValue = dataValue?.value;
      } else dataValue = null;
    }
    if (
      property !== "id" &&
      property !== "age" &&
      property !== "lengthOfService"
    ) {
      if (
        property === "accessLevel" ||
        property === "employment--salary" ||
        property === "employment--rate"
      ) {
        dataValue = parseFloat(dataValue);
        if (isNaN(dataValue)) dataValue = property === "accessLevel" ? 0.3 : 0;
      }
      if (property === "employment--regularDays") {
        const days = [false];
        for (let i = 1; i < 8; i++) {
          const search = dataValue?.find((item) => item?.value === i);
          let day = !!(search || dataValue[i] === true);
          days.push(day);
        }
        dataValue = days;
      }
      set(newData, property.replaceAll("--", "."), dataValue);
    }
  }
  return newData;
};

export const useFetchHolidayGroups = ({ account = null, callback }) => {
  const isMutating = useIsMutating({ mutationKey: "holidayGroups" });
  return useQuery(
    ["holidayGroups", account],
    () =>
      request({
        url: "/user-groups",
        params: { holiday: 1, account: account },
      }),
    {
      refetchInterval: false,
      select: (data) => data?.data?.items,
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};
export const useFetchUsersBasic = ({
  account = null,
  callback,
  level = null,
  archive = false,
}) => {
  const isMutating = useIsMutating({ mutationKey: "users" });
  return useQuery(
    ["usersBasic", account, level, archive],
    () =>
      request({
        url: "/users",
        params: { basic: 1, account: account, accessLevel: level },
      }),
    {
      refetchInterval: false,
      select: (data) =>
        data?.data?.items
          ?.filter((user) => {
            return !!(!user?.enabled === archive);
          })
          ?.sort((a, b) =>
            a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase())
          ),
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useFetchMe = ({ callback }) => {
  const isMutating = useIsMutating({ mutationKey: "users" });
  return useQuery(
    ["me"],
    () => {
      const isAdmin = !!(localStorage.getItem("admin") === "true");
      const loggedIn = !!(localStorage.getItem("loggedIn") === "true");
      if (isAdmin || !loggedIn) return Promise.resolve({});
      return request({
        url: `/auth/me`,
      });
    },
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: (data) => {
        const me = data?.data;
        return me;
      },
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useFetchUser = ({ account = null, userId = null, callback }) => {
  const isMutating = useIsMutating({ mutationKey: "users" });
  return useQuery(
    ["user", account, userId?.toString()],
    () => {
      if (!userId) return Promise.resolve({});
      return request({
        url: `/users/${userId}`,
        params: { account: account },
      });
    },
    {
      refetchInterval: false,
      select: (data) => data?.data,
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useUpdateUser = ({
  account = null,
  callback,
  level = null,
  approve = false,
  area = null,
}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const userId = data?.id;
      if (!userId) return Promise.resolve({});
      const newData = transformUserProfile(data);
      if (approve) {
        newData.approved = true;
      }
      if (area) {
        newData.updatedArea = area;
      }
      return request({
        url: `/users/${userId}`,
        method: "patch",
        params: { account: account },
        data: newData,
        json: true,
      });
    },
    {
      mutationKey: "users",
      onMutate: async (data) => {
        const userId = data?.id;

        // optimistic updates

        // update user
        await queryClient.cancelQueries(["user", account, userId?.toString()]);
        const currentUser = queryClient.getQueryData([
          "user",
          account,
          userId?.toString(),
        ])?.data;
        let newData = transformUserProfile(data);
        if (data?.hasOwnProperty("groups") && Array.isArray(data.groups)) {
          newData.groups = data.groups.map((item) => item?.label)?.join(", ");
        }
        newData = { ...currentUser, ...newData };
        await queryClient.setQueryData(["user", account, userId?.toString()], {
          data: newData,
        });

        // update users collection
        await queryClient.cancelQueries(["usersBasic", account, level, true]);
        await queryClient.cancelQueries(["usersBasic", account, level, false]);
        if (
          data?.hasOwnProperty("accessLevel") ||
          data?.hasOwnProperty("enabled") ||
          data?.details?.hasOwnProperty("firstName") ||
          data?.details?.hasOwnProperty("surname") ||
          data?.employment?.hasOwnProperty("location") ||
          data?.employment?.hasOwnProperty("department")
        ) {
          newData = { ...data };
          if (data?.details?.hasOwnProperty("firstName")) {
            newData.fullName = `${data.details.firstName} ${data?.details?.surname}`;
          }
          if (data?.employment?.hasOwnProperty("location")) {
            newData.location = data.employment.location;
          }
          if (data?.employment?.hasOwnProperty("department")) {
            newData.department = data.employment.department;
          }
          if (data?.hasOwnProperty("accessLevel")) {
            newData.accessLevel = data.accessLevel?.value;
          }

          const includedArchive = [true, false];
          includedArchive.forEach(async (archive) => {
            const currentUsers = queryClient.getQueryData([
              "usersBasic",
              account,
              level,
              archive,
            ])?.data?.items;

            if (currentUsers?.length > 0) {
              const index = currentUsers.findIndex(
                (item) => item.id === userId
              );
              if (index > -1) {
                currentUsers[index] = { ...currentUsers[index], ...newData };
                await queryClient.setQueryData(
                  ["usersBasic", account, level, archive],
                  {
                    data: {
                      items: currentUsers,
                    },
                  }
                );
              }
            }
          });
        }
      },
      onSettled: (returned, _v, data) => {
        const userId = returned?.data?.id;
        queryClient.refetchQueries(["user", account, userId?.toString()]);
        // update all reminders queries
        queryClient.invalidateQueries((key) => key[0] === "reminders");
      },
      onSuccess: callback,
    }
  );
};

export const useUpdateGroup = ({ account = null, callback, callbackError }) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const submission = { ...data };
      delete submission.groupId;
      const groupId = data?.groupId;
      if (!groupId) return Promise.resolve({});
      return request({
        url: `/user-groups/${groupId}`,
        method: "patch",
        params: { account: account },
        data: submission,
        json: true,
      });
    },
    {
      mutationKey: "holidayGroups",
      onMutate: async (data) => {
        const groupId = data?.groupId;

        // optimistic update group in groups collection

        await queryClient.cancelQueries(["holidayGroups", account]);
        if (groupId) {
          newData = { ...data };
          delete newData.id;

          const currentGroups = queryClient.getQueryData([
            "holidayGroups",
            account,
          ])?.data?.items;

          if (currentGroups?.length > 0) {
            const index = currentGroups.findIndex(
              (item) => item.groupId === groupId
            );
            if (index > -1) {
              currentGroups[index] = { ...currentGroups[index], ...newData };
              await queryClient.setQueryData(["holidayGroups", account], {
                data: {
                  items: currentGroups,
                },
              });
            }
          }
        }
      },
      onSuccess: callback,
      onError: callbackError || ((err) => console.log(err)),
    }
  );
};

export const useDeleteUser = ({ account = null, callback, level = null }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (userId) => {
      return request({
        url: `/users/${userId}`,
        params: { account: account },
        method: "delete",
      });
    },
    {
      mutationKey: "users",
      onMutate: async (userId) => {
        await queryClient.cancelQueries(["usersBasic", account, level, true]);
        await queryClient.cancelQueries(["usersBasic", account, level, false]);

        const includedArchive = [true, false];
        for (const archive of includedArchive) {
          const currentUserData = queryClient.getQueryData([
            "usersBasic",
            account,
            level,
            archive,
          ]);
          const currentUsers = currentUserData?.data?.items;

          // optimistic update data
          if (currentUsers?.length > 0) {
            const updated = [...currentUsers];
            const deleteIndex = updated?.findIndex(
              (user) => user?.id === userId
            );
            updated.splice(deleteIndex, 1);
            await queryClient.setQueryData(
              ["usersBasic", account, level, archive],
              {
                data: { items: [...updated] },
              }
            );
          }
        }
      },
      onSettled: (userId) => {
        queryClient.invalidateQueries(["usersBasic", account, level, true]);
        queryClient.invalidateQueries(["usersBasic", account, level, false]);
      },
      onSuccess: callback,
    }
  );
};

export const useAddUser = ({
  account = null,
  callback,
  callbackError,
  level = null,
}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const newData = transformUserProfile(data);
      return request({
        url: `/users`,
        method: "post",
        params: { account: account },
        data: newData,
        json: true,
      });
      return false;
    },
    {
      mutationKey: "users",
      onSettled: (returned, _v, data) => {
        queryClient.invalidateQueries(["usersBasic", account, level, true]);
        queryClient.invalidateQueries(["usersBasic", account, level, false]);
      },
      onSuccess: callback,
      onError: callbackError,
    }
  );
};
export const useCreateUserGroup = ({ account = null, callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      return request({
        url: `/user-groups`,
        method: "post",
        params: { account: account },
        data: data,
        json: true,
      });
    },
    {
      mutationKey: "holidayGroups",
      onSettled: () => {
        queryClient.invalidateQueries(["holidayGroups", account]);
      },
      onSuccess: callback,
    }
  );
};

export const useDeleteUserGroup = ({ account = null, callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (groupId) => {
      return request({
        url: `/user-groups/${groupId}`,
        params: { account: account },
        method: "delete",
      });
    },
    {
      mutationKey: "users",
      onMutate: async (groupId) => {
        await queryClient.cancelQueries(["holidayGroups", account]);

        const currentGroupsData = queryClient.getQueryData([
          "holidayGroups",
          account,
        ]);
        const currentGroups = currentGroupsData?.data?.items;

        // optimistic update data
        if (currentGroups?.length > 0) {
          const updated = [...currentGroups];
          const deleteIndex = updated?.findIndex(
            (group) => group?.groupId === groupId
          );
          updated.splice(deleteIndex, 1);
          await queryClient.setQueryData(["holidayGroups", account], {
            data: { items: [...updated] },
          });
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(["holidayGroups", account]);
      },
      onSuccess: callback,
    }
  );
};

export default useFetchUsersBasic;
