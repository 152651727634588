import {
  useQuery,
  useQueryClient,
  useMutation,
  useIsMutating,
} from "react-query";
import { request } from "../../../utilities/fetch.js";

const pagesTransform = (data) => {
  if (data?.data?.items) {
    return data.data.items
      .filter((page) => {
        return !!(page.description != "");
      })
      .map((page) => {
        const output = {
          id: page.id,
          label: page.description,
          slug: page.slug,
          enabled: !page.underConstruction,
          clientCanEdit: !!page.clientCanEdit,
          type: page.pageType,
          catId: page.catId,
          assignedContractUsers: page.assignedContractUsers,
          contentType: page.contentType,
          access: page.access,
          isContract: page.isContract,
        };
        if (page.content) output.content = page.content;
        return output;
      });
  }
  return null;
};
export const useFetchPages = ({ account = null, callback, refetch = true }) => {
  const isMutating = useIsMutating({ mutationKey: "pages" });
  return useQuery(
    ["pagesBasic", account],
    () => {
      if (account === false) return Promise.resolve({});
      return request({
        url: "/pages",
        params: { account: account },
      });
    },
    {
      refetchInterval: refetch === false ? false : 30000,
      refetchOnMount: refetch === false ? false : true,
      refetchOnWindowFocus: refetch === false ? false : true,
      select: pagesTransform,
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};
export const useFetchPagesSearch = ({
  account = null,
  callback,
  searchTerms,
}) => {
  const isMutating = useIsMutating({ mutationKey: "pages" });
  return useQuery(
    ["pagesSearch", account, searchTerms],
    () =>
      request({
        url: `/articles/search/${searchTerms}`,
        params: { account: account },
      }),
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: (data) => {
        return data?.data?.items;
      },
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useFetchSubstitutions = ({ account = null, callback }) => {
  const isMutating = useIsMutating({ mutationKey: "substitutions" });
  return useQuery(
    ["substitutions", account],
    () => request({ url: "/substitutions", params: { account: account } }),
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: isMutating === 0,
      select: (data) => {
        return data?.data;
      },
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
    }
  );
};

export const useFetchContentSummary = ({ account = null, slug, callback }) => {
  const isMutating = useIsMutating({ mutationKey: "pages" });
  return useQuery(
    ["content-summary", slug, account],
    () => {
      if (!slug) return Promise.resolve({});
      return request({
        url: `/content-summary/${slug}`,
        params: { account: account },
      });
    },
    {
      refetchInterval: 30000,
      select: (data) => {
        return data?.data?.items;
      },
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useFetchArticle = ({
  refetch = true,
  account = null,
  slug,
  callback,
}) => {
  const isMutating = useIsMutating({ mutationKey: "pages" });
  return useQuery(
    ["article", slug, account],
    () => {
      if (!slug) return Promise.resolve({});
      return request({
        url: `/articles/${slug}`,
        noToaster: true,
        params: { account: account },
      });
    },
    {
      refetchInterval: refetch === false ? false : 30000,
      refetchOnMount: refetch === false ? false : true,
      refetchOnWindowFocus: refetch === false ? false : true,
      select: (data) => {
        return data.data;
      },
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      retry: false,
      enabled: isMutating === 0,
    }
  );
};

export const useFetchNoAuthArticle = ({
  refetch = false,
  account = null,
  slug,
  callback,
}) => {
  const isMutating = useIsMutating({ mutationKey: "pages" });
  return useQuery(
    ["articleNoAuth", slug, account],
    () => {
      if (!slug) return Promise.resolve({});
      return request({
        url: `/articles/noauth/${slug}`,
        noToaster: true,
        params: { account: account },
      });
    },
    {
      refetchInterval: refetch === false ? false : 30000,
      refetchOnMount: refetch === false ? false : true,
      refetchOnWindowFocus: refetch === false ? false : true,
      select: (data) => {
        return data.data;
      },
      // onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      retry: false,
      enabled: isMutating === 0,
    }
  );
};

export const useCreatePage = ({ account = null, callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      if (!data?.description) return Promise.resolve({});
      return request({
        url: "/articles",
        method: "post",
        params: { account },
        data,
        json: true,
      });
    },
    {
      mutationKey: "pages",
      onMutate: async () => {
        await queryClient.cancelQueries(["pagesBasic", account]);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(["pagesBasic", account]);
        if (callback && typeof callback != "undefined") callback(data?.data);
      },
    }
  );
};

export const useEditPage = ({ account = null, callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      const pageId = data?.id;
      const sendData = {
        ...data,
      };
      // we can't update the content with this endpoint,
      // but we can send an array of section IDs (i.e. for re-ordering)
      if (sendData.content) {
        const content = sendData.content.map((section) => {
          return {
            contentId: section.contentId,
          };
        });
        sendData.contentPositions = content;
      }
      delete sendData.id;
      delete sendData.content;
      delete sendData.articleSlug;
      return request({
        url: `/articles/${pageId}`,
        method: "patch",
        params: { account: account },
        data: sendData,
        json: true,
      });
    },
    {
      mutationKey: "pages",
      onMutate: async (data) => {
        await queryClient.cancelQueries(["pagesBasic", account]);
        await queryClient.cancelQueries([
          "article",
          data?.articleSlug,
          account,
        ]);
        const pageId = data.id;
        const sendData = {
          ...data,
          enabled: !data.underConstruction,
        };
        delete sendData.id;

        // optimistic update pages data
        const currentPages = queryClient.getQueryData(["pagesBasic", account])
          ?.data?.items;
        if (currentPages) {
          const pageIndex = currentPages.findIndex(
            (page) => page.id === pageId
          );
          if (pageIndex >= 0) {
            const currentPage = {
              ...currentPages[pageIndex],
              ...sendData,
            };
            currentPages[pageIndex] = currentPage;
            await queryClient.setQueryData(["pagesBasic", account], {
              data: { items: [...currentPages] },
            });
          }
        }

        // optimistic update article
        const currentArticle = queryClient.getQueryData([
          "article",
          data?.articleSlug,
          account,
        ])?.data;
        if (currentArticle) {
          const updatedArticle = {
            ...currentArticle,
            ...data,
          };
          await queryClient.setQueryData(
            ["article", data?.articleSlug, account],
            {
              data: { ...updatedArticle },
            }
          );
          // 'sleep' 10s, allow move animation to complete
          await new Promise((r) => setTimeout(r, 500));
          updatedArticle.content = updatedArticle.content.map((section) => {
            delete section.moved;
            return section;
          });
          // 'moved' property was used to add css class.
          // now update with the property removed
          await queryClient.setQueryData(
            ["article", data?.articleSlug, account],
            {
              data: { ...updatedArticle },
            }
          );
        }
      },
      onSettled: async (_d, _u, data) => {
        queryClient.invalidateQueries(["article", data?.articleSlug, account]);
        queryClient.invalidateQueries(["pagesBasic", account]);
        if (callback && typeof callback != "undefined") callback(data);
      },
    }
  );
};

export const useCreateSection = ({ account = null, articleSlug, callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      if (!data.contentType) {
        data.contentType = "content";
      }
      return request({
        url: `/articles/${articleSlug}`,
        method: "post",
        params: { account },
        data,
        json: true,
      });
    },
    {
      mutationKey: "pages",
      onMutate: (params) => {
        contentType = params.type;
        contentTypeId = params.id;
      },
      onSuccess: async (data) => {
        // update data
        const currentArticle = queryClient.getQueryData([
          "article",
          articleSlug,
          account,
        ])?.data;
        const currentContent = currentArticle?.content;
        currentContent.push(data?.data);
        currentArticle.content = currentContent;
        await queryClient.setQueryData(["article", articleSlug, account], {
          data: { ...currentArticle },
        });
        if (callback && typeof callback != "undefined") callback(data?.data);
      },
    }
  );
};

export const useEditSection = ({ account = null, callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      const sectionId = data?.id;
      const sendData = {
        ...data,
      };
      delete sendData.id;
      delete sendData.articleSlug;
      return request({
        url: `/content-sections/${sectionId}`,
        method: "patch",
        params: { account: account },
        data: sendData,
        json: true,
      });
    },
    {
      mutationKey: "pages",
      onMutate: async (data) => {
        const sectionId = data.id;
        const articleSlug = data.articleSlug;
        await queryClient.cancelQueries(["article", articleSlug, account]);
        const sendData = { ...data };
        delete sendData.id;
        delete sendData.articleSlug;
        // optimistic update data
        const currentArticle = queryClient.getQueryData([
          "article",
          articleSlug,
          account,
        ])?.data;
        const currentContent = [...currentArticle?.content];
        const sectionIndex = currentContent.findIndex(
          (section) => section.contentId === sectionId
        );
        if (sectionIndex >= 0) {
          const currentSection = {
            ...currentContent[sectionIndex],
            ...sendData,
          };
          const update = { ...currentArticle };
          // if we've changed the image, use property to render
          // skeleton until we've fetched the full image data
          if (sendData.image?.imageId) {
            currentSection.image = {
              ...sendData.image,
              skeleton: true,
            };
          }
          update.content[sectionIndex] = currentSection;
          await queryClient.setQueryData(["article", articleSlug, account], {
            data: { ...update },
          });
        }
        queryClient.invalidateQueries(["article", articleSlug, account]);
        if (callback && typeof callback != "undefined") callback(data);
      },
      onSettled: async (_d, _u, data) => {
        queryClient.invalidateQueries(["article", data?.articleSlug, account]);
      },
    }
  );
};

export const useDeleteArticle = ({ account = null, callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (pageId) => {
      return request({
        url: `/articles/${pageId}`,
        method: "delete",
        params: { account: account },
      });
    },
    {
      mutationKey: "pages",
      onMutate: async (pageId) => {
        await queryClient.cancelQueries(["pagesBasic", account]);
        const currentPages = queryClient.getQueryData(["pagesBasic", account])
          ?.data?.items;
        // optimistic update data
        if (currentPages?.length > 0) {
          const deleteIndex = currentPages.findIndex(
            (page) => page?.id === pageId
          );
          currentPages.splice(deleteIndex, 1);
          await queryClient.setQueryData(["pagesBasic", account], {
            data: { items: [...currentPages] },
          });
          queryClient.invalidateQueries(["pagesBasic", account]);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(["pagesBasic", account]);
      },
      onSuccess: callback,
    }
  );
};

export const useDeleteSection = ({ account = null, callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id: sectionId }) => {
      return request({
        url: `/content-sections/${sectionId}`,
        method: "delete",
        params: { account: account },
        json: true,
      });
    },
    {
      mutationKey: "pages",
      onMutate: async ({ id: sectionId, articleSlug }) => {
        await queryClient.cancelQueries(["article", articleSlug, account]);
        // optimistic update data
        const currentArticle = queryClient.getQueryData([
          "article",
          articleSlug,
          account,
        ])?.data;
        const currentContent = currentArticle?.content;
        const sectionIndex = currentContent.findIndex(
          (section) => section.contentId === sectionId
        );
        if (sectionIndex >= 0) {
          currentContent.splice(sectionIndex, 1);
          currentArticle.content = currentContent;
          await queryClient.setQueryData(["article", articleSlug, account], {
            data: { ...currentArticle },
          });
        }
        queryClient.invalidateQueries(["article", articleSlug, account]);
        if (callback && typeof callback != "undefined") callback(data);
      },
      onSettled: async (_d, _u, data) => {
        queryClient.invalidateQueries(["article", data?.articleSlug, account]);
      },
    }
  );
};

export default useFetchPages;
